@font-face {
  font-family: solitreo;
  src: url(Solitreo-Regular.ttf);
}
@font-face {
  font-family: rashi;
  src: url(Rashi.ttf);
}

/* Styles for the TypeAndDisplay component */
.type-and-display {
  display: flex;
  width: 80%;
  /* min-height: 100vh; */
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  background: #444133;
  font-family: Commissioner, sans-serif;
  box-sizing: border-box;

  border-radius: 16px;
  font-family: Commissioner, sans-serif;
}
#title {
  align-self: stretch;
  color: #fff;
  font-family: Commissioner;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.transcibe-area {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 40px;
  align-self: stretch;
}

/* target the inpute child of type-and-display */
.input-box {
  display: flex;
  height: 128px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  background: #f2f2f2;
}

.input-box textarea {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  box-sizing: border-box;
  outline: none;
  font-size: 16px;
  line-height: 1.5;
  resize: none;
  overflow: hidden;
  font-family: Commissioner, sans-serif;
}

.input-box textarea::placeholder {
  color: #444133;
}

.transcribed {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}
.types {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  align-self: stretch;
  color: #ffffff;
  font-family: Commissioner, sans-serif;
}
.type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 2px;
  cursor: pointer;
}
.selected {
  text-decoration: underline;
  font-weight: bold;
}

.hebrew-text {
  display: flex;
  height: 128px;
  padding: 20px 18px;
  flex-wrap: wrap;
  align-items: flex-start;
  /* gap: 8px; */
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  font-size: larger;
  background: #fff;
  direction: rtl;
  text-align: right;
  unicode-bidi: bidi-override;
  /* font-family: solitreo; */
}
.solitreo {
  font-family: solitreo;
}
.rashi {
  font-family: rashi;
}
/* Styles for individual character divs */
/* Styles for individual word divs */
.word-div {
  display: inline-block; /* Ensures side-by-side display */
  margin-right: 0; /* Remove the right margin */
  margin-left: 10px; /* Adds space between words, used for LTR languages */
}
.word-div:hover {
  color: #092556; /* Add a light gray background on hover */
}

.svg-container {
  display: flex; /* Use flexbox to layout children */
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 10px; /* Adjust this value to your liking for spacing */
  margin-right: 10px; /* Adjust this value to your liking for spacing */
}
.svg {
  padding-left: 20px;
}

@media screen and (max-width: 800px) {
  /*  */
  .transcribed {
    width: 100%;
  }
  .transcibe-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .input-box {
    display: flex;
    height: 104px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }
  input {
    /* height: 104px; */
    padding: 16px;
    gap: 8px;
  }
  img {
    transform: rotate(90deg);
  }
  .img-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 50px;
  }
}
.button-12 {
  padding: 6px 14px;
  font-family: Commissioner, -apple-system, BlinkMacSystemFont, "Roboto",
    sans-serif;
  border-radius: 6px;
  border: none;

  background: #444133;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.5),
    0px 0px 0px 0.5px rgba(0, 0, 0, 0.12);
  color: #dfdedf;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-12:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2),
    0px 0.5px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}
